/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import IdeaList from './Components/IdeaList';
import { css } from '@emotion/react';
import { Text } from './Constants/Text';
import { useIdeasQuery } from './hooks/useIdeasQuery';
import ConfirmationModal from './Components/ConfirmationModal';
import { useCompletion } from './hooks/useCompletion';
import { toast } from 'react-toastify';
import { useAuth } from './context/AuthContext';
import { arrayMove } from '@dnd-kit/sortable';
import { Idea } from './Constants/Type';

const containerStyles = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;
  height: 100%;
`;

const titleStyles = css`
  font-size: ${Text.TITLE.size};
  font-weight: ${Text.TITLE.weight};
`;

const loadingStyles = css`
  text-align: center;
  padding: 2rem;
`;

const ideasContainerStyles = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const Ideas = () => {
  const { 
    ideas: serverIdeas, 
    archivedIdeas: serverArchivedIdeas,
    isLoading, 
    isLoadingArchived,
    error, 
    archivedError,
    deleteIdea, 
    isDeleting, 
    refreshIdea, 
    isRefreshing,
    updateIdea,
    isUpdating,
    createIdea,
    isCreating,
    createEmptyIdea,
    isCreatingEmpty,
    updateIdeas,
    reorderArchivedIdeas,
    isReorderingArchived,
    moveIdeaBetweenLists,
    isMovingBetweenLists,
    generatePostContent,
    isGeneratingPostContent,
  } = useIdeasQuery();
  const { generateCompletion } = useCompletion();
  const [localIdeas, setLocalIdeas] = useState(serverIdeas || []);
  const [localArchivedIdeas, setLocalArchivedIdeas] = useState(serverArchivedIdeas || []);
  const [ideaToDelete, setIdeaToDelete] = useState<string | null>(null);
  const [refreshingIdeaId, setRefreshingIdeaId] = useState<string | undefined>(undefined);
  const [generatingTextIdeaId, setGeneratingTextIdeaId] = useState<string | undefined>(undefined);
  const { userState } = useAuth();
  const [editingIdeaId, setEditingIdeaId] = useState<string | null>(null);
  const [recentlyCreatedIds, setRecentlyCreatedIds] = useState<string[]>([]);

  useEffect(() => {
    if (serverIdeas && JSON.stringify(serverIdeas) !== JSON.stringify(localIdeas)) {
      console.log('setting local ideas');
      setLocalIdeas(serverIdeas);
    }
    if (serverArchivedIdeas && JSON.stringify(serverArchivedIdeas) !== JSON.stringify(localArchivedIdeas)) {
      console.log('setting local archived ideas');
      setLocalArchivedIdeas(serverArchivedIdeas);
    }
  }, [serverIdeas, serverArchivedIdeas]);

  useEffect(() => {
    return () => {
      setRecentlyCreatedIds([]);
    };
  }, []);

  const handleDeleteClick = (ideaId: string) => {
    setIdeaToDelete(ideaId);
  };

  const handleConfirmDelete = async () => {
    if (ideaToDelete) {
      await deleteIdea(ideaToDelete);
      setIdeaToDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setIdeaToDelete(null);
  };

  const handleRefreshClick = (ideaId: string) => {
    setRefreshingIdeaId(ideaId);
    refreshIdea(ideaId, {
      onSettled: () => {
        setRefreshingIdeaId(undefined);
      }
    });
  };

  const handleUpdate = (ideaId: string, title: string, description: string, postDescription?: string, postHashtags?: string[]) => {
    updateIdea({ ideaId, title, description, postDescription, postHashtags });
  };

  const handleGenerateText = async (ideaId: string, title: string, description: string, 
    onSuccess: (newTitle: string, newDescription: string) => void) => {
    try {
      setGeneratingTextIdeaId(ideaId);

      const prompt = `
        You are tasked with helping a small business owner come up with a social media content idea.
        We have collected the following information about the business owner:
        ${JSON.stringify(userState)}

        The user has started writing an idea with:
        ${title ? `Title: ${title}` : 'No title provided'}
        ${description ? `Description: ${description}` : 'No description provided'}

        Please flesh out/enhance this idea, keeping any provided content as inspiration. If either the title or description is empty, create new content that fits with any provided text.
        If no text is provided, create a new idea.
        Important: the description isn't the description of the content for the user to post, it's a description of the idea itself.
        Keep the description of the idea concise and don't include unnecessary information like content type, format, platform e.g. dont say "Create a short video of ..."
        The tone value is a number between 1 and 5, where 1 is playful and 5 is serious.

        Return the idea in a JSON array like this: {ideas: [{title: 'idea1', description: 'description1'}]}
      `;

      const completion = await generateCompletion(prompt);
      const { ideas: [newIdea] } = JSON.parse(completion);
      
      onSuccess(newIdea.title, newIdea.description);
    } catch (error) {
      toast.error('Failed to generate text');
    } finally {
      setGeneratingTextIdeaId(undefined);
    }
  };

  const handleCreateIdea = () => {
    createIdea(undefined, {
      onSuccess: (newIdea) => {
        setRecentlyCreatedIds(prev => [...prev, newIdea.id]);
      }
    });
  };

  const handleCreateEmptyIdea = () => {
    createEmptyIdea(undefined, {
      onSuccess: (newIdea) => {
        setEditingIdeaId(newIdea.id);
        setRecentlyCreatedIds(prev => [...prev, newIdea.id]);
      }
    });
  };

  const handleReorder = (startIndex: number, endIndex: number) => {
    const reorderedIdeas = arrayMove(localIdeas, startIndex, endIndex);
    setLocalIdeas(reorderedIdeas);
    updateIdeas({ newIdeas: reorderedIdeas, originalIdeas: localIdeas });
  };

  const handleReorderArchived = (startIndex: number, endIndex: number) => {
    const reorderedArchivedIdeas = arrayMove(localArchivedIdeas, startIndex, endIndex);
    setLocalArchivedIdeas(reorderedArchivedIdeas);
    reorderArchivedIdeas(reorderedArchivedIdeas);
  };

  const handleMoveBetweenLists = ( 
    newActiveIdeas: Idea[], 
    newArchivedIdeas: Idea[],
    movedIdea: Idea | null
  ) => {
    moveIdeaBetweenLists({ newActiveIdeas, newArchivedIdeas, movedIdea });
  };

  if (isLoading || isLoadingArchived) {
    return (
      <div css={containerStyles}>
        <h1 css={titleStyles}>My Ideas</h1>
        <div css={loadingStyles}>Loading ideas...</div>
      </div>
    );
  }

  if (error || archivedError) {
    return (
      <div css={containerStyles}>
        <h1 css={titleStyles}>My Ideas</h1>
        <div css={loadingStyles}>
          Error loading ideas: {error?.message || archivedError?.message}
        </div>
      </div>
    );
  }
  
  const isGeneratingText = generatingTextIdeaId !== undefined;

  return (
    <div css={containerStyles}>
      {(localIdeas.length > 0 || localArchivedIdeas.length > 0) ? (
        <div css={ideasContainerStyles}>
          <IdeaList 
            ideas={localIdeas}
            archivedIdeas={localArchivedIdeas}
            recentlyCreatedIds={recentlyCreatedIds}
            onDelete={handleDeleteClick}
            isLoading={isLoading || isDeleting || isRefreshing || isUpdating || isGeneratingText || isCreating || isCreatingEmpty || isReorderingArchived || isMovingBetweenLists || isGeneratingPostContent}
            onRefresh={handleRefreshClick}
            refreshingIdeaId={refreshingIdeaId}
            onUpdate={handleUpdate}
            onGenerateText={handleGenerateText}
            generatingTextIdeaId={generatingTextIdeaId}
            onCreateIdea={handleCreateIdea}
            onCreateEmptyIdea={handleCreateEmptyIdea}
            editingIdeaId={editingIdeaId}
            setEditingIdeaId={setEditingIdeaId}
            onReorder={handleReorder}
            onReorderArchived={handleReorderArchived}
            setLocalIdeas={setLocalIdeas}
            setLocalArchivedIdeas={setLocalArchivedIdeas}
            moveIdeaBetweenLists={handleMoveBetweenLists}
            onGeneratePostContent={generatePostContent}
            isGeneratingPostContent={isGeneratingPostContent}
          />
          <ConfirmationModal
            isOpen={ideaToDelete !== null}
            title="Delete Idea"
            message="Are you sure you want to delete this idea?"
            onConfirm={handleConfirmDelete}
            onCancel={handleCancelDelete}
            isConfirming={isDeleting}
          />
        </div>
      ) : (
        <p>No ideas yet. Start a conversation with Flynn to generate some ideas!</p>
      )}
    </div>
  );
};

export default Ideas;
