/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { css } from "@emotion/react";
import { Idea } from "../Constants/Type";
import { Colours } from "../Constants/Colours";
import { ReactComponent as PlusIcon } from '../icons/Plus.svg';
import Spinner from "./Spinner";
import Button from "./Button";
import { 
  DndContext, 
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragOverEvent,
  useDroppable,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { SortableIdea } from '../Components/SortableIdea';
import EditableIdea from './EditableIdea';
import { Text } from "../Constants/Text";
import ArchivedIdea from './ArchivedIdea';
import IdeaPanel from './IdeaPanel';

interface IdeaListProps {
  ideas: Idea[];
  recentlyCreatedIds: string[];
  onDelete: (ideaId: string) => void;
  isLoading: boolean;
  onRefresh: (ideaId: string) => void;
  refreshingIdeaId?: string;
  onUpdate: (ideaId: string, title: string, description: string, postDescription?: string, postHashtags?: string[]) => void;
  onGenerateText: (ideaId: string, title: string, description: string, onSuccess: (newTitle: string, newDescription: string) => void) => void;
  generatingTextIdeaId?: string;
  onCreateIdea: () => void;
  onCreateEmptyIdea: () => void;
  editingIdeaId: string | null;
  setEditingIdeaId: (id: string | null) => void;
  onReorder: (startIndex: number, endIndex: number) => void;
  archivedIdeas: Idea[];
  onReorderArchived: (startIndex: number, endIndex: number) => void;
  setLocalIdeas: (ideas: Idea[]) => void;
  setLocalArchivedIdeas: (ideas: Idea[]) => void;
  moveIdeaBetweenLists: (newActiveIdeas: Idea[], newArchivedIdeas: Idea[], movedIdea: Idea | null) => void;
  onGeneratePostContent: (params: { ideaId: string, title: string, description: string }) => void;
  isGeneratingPostContent: boolean;
}

const ideasContainerStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 640px;
  gap: 12px;
  height: 100%;
  margin-bottom: 50px;
`;

const listLabelStyles = css`
  margin: 0 0 8px 0;
  font-size: ${Text.TITLE_SMALL.size};
  color: ${Colours.TEXT};
  align-self: flex-start;
`;

const listContainerStyles = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 30px;
  border: 1px solid ${Colours.DIVIDER};
`;

const addIdeaButtonStyles = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  border-radius: 30px 30px 0 0;
  width: 100%;
  gap: 10px;
`;

const emptyContainerStyles = css`
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: ${Colours.TEXT};
  font-style: italic;
`;

const Droppable = ({ id, children }: { id: string, children: React.ReactNode }) => {
  const { setNodeRef } = useDroppable({
    id: id,
  });

  return (
    <div ref={setNodeRef}>
      {children}
    </div>
  );
};

const IdeaList: React.FC<IdeaListProps> = ({ 
  ideas,
  recentlyCreatedIds,
  onDelete, 
  isLoading, 
  onRefresh,
  refreshingIdeaId,
  onUpdate,
  onGenerateText,
  generatingTextIdeaId,
  onCreateIdea,
  onCreateEmptyIdea,
  editingIdeaId,
  setEditingIdeaId,
  onReorder,
  archivedIdeas,
  onReorderArchived,
  setLocalIdeas,
  setLocalArchivedIdeas,
  moveIdeaBetweenLists,
  onGeneratePostContent,
  isGeneratingPostContent,
}) => {
  const [editedTitle, setEditedTitle] = useState('');
  const [editedDescription, setEditedDescription] = useState('');
  const [movedIdea, setMovedIdea] = useState<Idea | null>(null);
  const [clonedActiveIdeas, setClonedActiveIdeas] = useState<Idea[] | null>(null);
  const [clonedArchivedIdeas, setClonedArchivedIdeas] = useState<Idea[] | null>(null);
  const [dragSourceContainer, setDragSourceContainer] = useState<string | null>(null);
  const [selectedIdeaId, setSelectedIdeaId] = useState<string | null>(null);
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  // Filter ideas into recent and existing
  const recentIdeas = ideas.filter(idea => recentlyCreatedIds.includes(idea.id));
  const existingIdeas = ideas.filter(idea => !recentlyCreatedIds.includes(idea.id));

  // Derive selected idea from the ideas arrays
  const selectedIdea = selectedIdeaId ? 
    [...ideas].find(idea => idea.id === selectedIdeaId) ?? null : 
    null;

  const handleEdit = (idea: Idea) => {
    setEditingIdeaId(idea.id);
    setEditedTitle(idea.title || '');
    setEditedDescription(idea.description);
  };

  const handleCancel = () => {
    setEditingIdeaId(null);
    setEditedTitle('');
    setEditedDescription('');
  };

  const handleSave = (ideaId: string) => {
    onUpdate(ideaId, editedTitle, editedDescription);
    setEditingIdeaId(null);
    setEditedTitle('');
    setEditedDescription('');
  };

  const handleGenerateTextClick = (ideaId: string) => {
    onGenerateText(
      ideaId, 
      editedTitle, 
      editedDescription,
      (newTitle, newDescription) => {
        setEditedTitle(newTitle);
        setEditedDescription(newDescription);
      }
    );
  };

  const editingIdea = editingIdeaId !== null;

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    
    if (!over || active.id === over.id) {
      return;
    }

    const oldIndex = ideas.findIndex((idea) => idea.id === active.id);
    const newIndex = ideas.findIndex((idea) => idea.id === over.id);
    
    onReorder(oldIndex, newIndex);
  };

  const handleArchivedDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    console.log('archived drag end!');
    
    if (!over || active.id === over.id) {
      return;
    }

    const oldIndex = archivedIdeas.findIndex((idea) => idea.id === active.id);
    const newIndex = archivedIdeas.findIndex((idea) => idea.id === over.id);
    
    onReorderArchived(oldIndex, newIndex);
  };

  const findContainer = (id: string) => {
    if (existingIdeas.find(idea => idea.id === id)) return 'existing';
    if (archivedIdeas.find(idea => idea.id === id)) return 'archived';
    return null;
  };

  const handleDragOver = (event: DragOverEvent) => {
    const { active, over } = event;
    
    if (!over) return;

    const activeId = active.id as string;
    const overId = over.id as string;

    const activeContainer = findContainer(activeId);
    // Treat the droppable the same as 'archived' container
    const overContainer = overId === 'archived-container' ? 'archived' : findContainer(overId);

    if (!activeContainer || !overContainer || activeContainer === overContainer) {
      return;
    }

    // Only allow moves if:
    // 1. Item originated from archived and is moving to existing
    // 2. Item originated from archived, was moved to existing, and is being moved back
    const isValidMove = 
      dragSourceContainer === 'archived' && 
      (overContainer === 'existing' || (overContainer === 'archived' && activeContainer === 'existing'));

    if (!isValidMove) {
      return;
    }

    const activeItems = activeContainer === 'existing' ? existingIdeas : archivedIdeas;
    const overItems = overContainer === 'existing' ? existingIdeas : archivedIdeas;

    const activeIndex = activeItems.findIndex(idea => idea.id === activeId);
    // When dropping into empty archive container, add to the end
    const overIndex = overId === 'archived-container' ? overItems.length : overItems.findIndex(idea => idea.id === overId);

    if (activeIndex === -1 || overIndex === -1) return;

    const newActiveItems = [...activeItems];
    const newOverItems = [...overItems];

    const [movedIdea] = newActiveItems.splice(activeIndex, 1);
    newOverItems.splice(overIndex, 0, movedIdea);

    if (activeContainer === 'existing') {
      setLocalIdeas(newActiveItems);
      setLocalArchivedIdeas(newOverItems);
    } else {
      setMovedIdea(movedIdea);
      setLocalIdeas(newOverItems);
      setLocalArchivedIdeas(newActiveItems);
    }
  };

  const handleCombinedDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    
    if (!over) return;

    const activeId = active.id as string;
    const overId = over.id as string;

    const currentContainer = findContainer(activeId);

    if (dragSourceContainer === currentContainer) {
      if (currentContainer === 'existing') {
        handleDragEnd(event);
      } else if (currentContainer === 'archived') {
        handleArchivedDragEnd(event);
      }
    } else if (dragSourceContainer === 'archived' && currentContainer === 'existing') {
      console.log('moving from archived to existing!');
      moveIdeaBetweenLists(ideas, archivedIdeas, movedIdea);
      setMovedIdea(null);
    }

    setDragSourceContainer(null);
  };

  const handleDragCancel = () => {
    console.log('drag cancelled!');
    // Reset lists to their original state if drag is cancelled
    if (clonedActiveIdeas && clonedArchivedIdeas) {
      setLocalIdeas(clonedActiveIdeas);
      setLocalArchivedIdeas(clonedArchivedIdeas);
    }
    setMovedIdea(null);
    setClonedActiveIdeas(null);
    setClonedArchivedIdeas(null);
  };

  const handleOpenPanel = (idea: Idea) => {
    setSelectedIdeaId(idea.id);
    setIsPanelOpen(true);
  };

  const handleClosePanel = () => {
    setIsPanelOpen(false);
    // We can clear the ID after the animation completes
    setTimeout(() => {
      setSelectedIdeaId(null);
    }, 300);
  };

  const handlePanelUpdate = (ideaId: string, title: string, description: string, postDescription?: string, postHashtags?: string[]) => {
    onUpdate(ideaId, title, description, postDescription, postHashtags);
  };

  const handlePanelDelete = (ideaId: string) => {
    onDelete(ideaId);
  };

  const renderIdeaList = (ideaList: Idea[]) => (
    <SortableContext 
      items={ideaList.map(idea => idea.id)}
      strategy={verticalListSortingStrategy}
    >
      {ideaList.map((idea, index) => {
        const isRefreshing = refreshingIdeaId === idea.id;
        const isEditing = editingIdeaId === idea.id;
        const isOtherIdeaBeingEdited = editingIdeaId !== null && editingIdeaId !== idea.id;
        const isDisabled = isLoading || isOtherIdeaBeingEdited;

        return (
          <SortableIdea
            idea={idea}
            isRefreshing={isRefreshing}
            isEditing={isEditing}
            isDisabled={isDisabled}
            key={idea.id}
            isLastItem={index === ideaList.length - 1}
          >
            <EditableIdea
              idea={idea}
              isRefreshing={isRefreshing}
              isEditing={isEditing}
              isDisabled={isDisabled}
              editedTitle={editedTitle}
              editedDescription={editedDescription}
              onEditedTitleChange={setEditedTitle}
              onEditedDescriptionChange={setEditedDescription}
              onDelete={onDelete}
              onRefresh={onRefresh}
              onEdit={handleEdit}
              onCancel={handleCancel}
              onSave={handleSave}
              onGenerateText={handleGenerateTextClick}
              generatingTextIdeaId={generatingTextIdeaId}
              isLoading={isLoading}
              onOpenDetails={handleOpenPanel}
            />
          </SortableIdea>
        );
      })}
    </SortableContext>
  );

  const renderArchivedIdeas = (archivedIdeas: Idea[]) => (
    <SortableContext 
      items={archivedIdeas.map(archivedIdea => archivedIdea.id)}
      strategy={verticalListSortingStrategy}
    >
      {archivedIdeas.length > 0 ? (
        <>
          <h2 css={listLabelStyles}>Archived Ideas</h2>
          <div css={listContainerStyles}>
          {archivedIdeas.map((idea, index) => (
            <SortableIdea
              key={idea.id}
              idea={idea}
              isRefreshing={false}
              isEditing={false}
              isDisabled={false}
              isLastItem={index === archivedIdeas.length - 1}
            >
              <ArchivedIdea idea={idea}/>
            </SortableIdea>
          ))}
        </div>
        </>
      ) : (
        // Only show empty droppable container if we're dragging from archive
        dragSourceContainer === 'archived' ? (
          <>
            <h2 css={listLabelStyles}>Archived Ideas</h2>
            <div css={listContainerStyles}>
              <Droppable id="archived-container">
            <div css={emptyContainerStyles}>
            </div>
          </Droppable>
          </div>
          </>
        ) : null
      )}
    </SortableContext>
  );

  return (
    <div css={ideasContainerStyles}>
      <div css={addIdeaButtonStyles}>
        <Button 
          variant="secondary" 
          icon={isLoading ? <Spinner /> : <PlusIcon />}
          onClick={onCreateIdea}
          disabled={isLoading || editingIdea}
        >
          {isLoading ? "" : "Generate idea"}
        </Button>
        <Button 
          variant="secondary" 
          icon={isLoading ? <Spinner /> : <PlusIcon />}
          onClick={onCreateEmptyIdea}
          disabled={isLoading || editingIdea}
        >
          {isLoading ? "" : "Empty idea"}
        </Button>
      </div>
      {recentIdeas.length > 0 && (
        <>
          <h2 css={listLabelStyles}>Recently Added</h2>
          <div css={listContainerStyles}>
            <DndContext 
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              {renderIdeaList(recentIdeas)}
            </DndContext>
          </div>
        </>
      )}
      {/* gap */}
      {recentIdeas.length > 0 && <div css={{ height: '20px' }} /> }
      <DndContext 
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleCombinedDragEnd}
        onDragOver={handleDragOver}
        onDragStart={(event) => {
          // Store the current state and source container when drag starts
          setClonedActiveIdeas(existingIdeas);
          setClonedArchivedIdeas(archivedIdeas);
          setDragSourceContainer(findContainer(event.active.id as string));
        }}
        onDragCancel={() => {
          handleDragCancel();
          setDragSourceContainer(null);
        }}
      >
        {existingIdeas.length > 0 && (
          <>
            <h2 css={listLabelStyles}>Upcoming Ideas</h2>
            <div css={listContainerStyles}>
              {renderIdeaList(existingIdeas)}
            </div>
          </>
        )}
        {/* gap */}
        {existingIdeas.length > 0 && <div css={{ height: '20px' }} /> }
        {renderArchivedIdeas(archivedIdeas)}
      </DndContext>
      <IdeaPanel
        idea={selectedIdea}
        isOpen={isPanelOpen}
        onClose={handleClosePanel}
        onDelete={handlePanelDelete}
        onRefresh={onRefresh}
        onUpdate={handlePanelUpdate}
        onGenerateText={onGenerateText}
        isLoading={isLoading}
        refreshingIdeaId={refreshingIdeaId}
        generatingTextIdeaId={generatingTextIdeaId}
        onGeneratePostContent={onGeneratePostContent}
        isGeneratingPostContent={isGeneratingPostContent}
      />
    </div>
  );
};

export default IdeaList;
