/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { css, keyframes } from '@emotion/react';
import { Colours } from '../Constants/Colours';
import { Text } from '../Constants/Text';

interface TooltipProps {
  content: string;
  children: React.ReactNode;
  position?: 'top' | 'top-left' | 'top-right' | 'bottom' | 'bottom-left' | 'bottom-right' | 'left' | 'right';
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const tooltipContainerStyles = css`
  position: relative;
  display: inline-block;
`;

const getTooltipStyles = (position: TooltipProps['position']) => css`
  position: absolute;
  background-color: ${Colours.TEXT};
  color: ${Colours.BACKGROUND};
  padding: 8px 12px;
  border-radius: 8px;
  font-size: ${Text.SMALL_BODY.size};
  white-space: nowrap;
  pointer-events: none;
  animation: ${fadeIn} 0.2s ease-in-out;
  animation-delay: 0.4s;
  animation-fill-mode: backwards;
  z-index: 1000;

  ${position === 'top' && `
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(-8px);
  `}

  ${position === 'top-left' && `
    bottom: 100%;
    left: 0;
    transform: translateY(-8px);
  `}

  ${position === 'top-right' && `
    bottom: 100%;
    right: 0;
    transform: translateY(-8px);
  `}

  ${position === 'bottom' && `
    top: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(8px);
  `}

  ${position === 'bottom-left' && `
    top: 100%;
    left: 0;
    transform: translateY(8px);
  `}

  ${position === 'bottom-right' && `
    top: 100%;
    right: 0;
    transform: translateY(8px);
  `}

  ${position === 'left' && `
    right: 100%;
    top: 50%;
    transform: translateX(-8px) translateY(-50%);
  `}

  ${position === 'right' && `
    left: 100%;
    top: 50%;
    transform: translateX(8px) translateY(-50%);
  `}
`;

const Tooltip: React.FC<TooltipProps> = ({ 
  content, 
  children, 
  position = 'top' 
}) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div 
      css={tooltipContainerStyles}
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      {isVisible && (
        <div css={getTooltipStyles(position)}>
          {content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
