const FontSizes = {
    TITLE: "32px",
    TITLE_SMALL: "20px",
    SUBTITLE: "16px",
    HEADING: "16px",
    BODY: "16px",
    SMALL_BODY: "14px",
    LABEL: "12px",


    small: "12px",
    medium: "14px",
    large: "16px",
};

const FontWeight = {
    REGULAR: 400,
    SEMI_BOLD: 500,
    BOLD: 600,
};

export const Text = {
  TITLE: {
    size: FontSizes.TITLE,
    weight: FontWeight.BOLD,
  },
  TITLE_SMALL: {
    size: FontSizes.TITLE_SMALL,
    weight: FontWeight.BOLD,
  },
  SUBTITLE: {
    size: FontSizes.SUBTITLE,
    weight: FontWeight.BOLD,
  },
  HEADING: {
    size: FontSizes.HEADING,
    weight: FontWeight.SEMI_BOLD,
  },
  BODY: {
    size: FontSizes.BODY,
    weight: FontWeight.REGULAR,
  },
  SMALL_BODY: {
    size: FontSizes.SMALL_BODY,
    weight: FontWeight.REGULAR,
  },
  LABEL: {
    size: FontSizes.LABEL,
    weight: FontWeight.REGULAR,
  },
};