import { 
  ProcessType, 
  OnboardingStep,
  AnalyticsUpdateStep,
  IdeationStep,
  PlanningStep,
  ShowContentPlanStep,
  BrainstormQuickIdeasStep,
  ShowContentIdeasStep,
  Process
} from '../Constants/Type';

export const getPreviousProcessMessage = (previousProcess: Process<ProcessType> | undefined): string => {
    console.log("previousProcess", previousProcess);
  if (!previousProcess) return "";

  const messageMap: { [key in ProcessType]?: { [key: string]: string } } = {
    [ProcessType.ONBOARDING]: {
      [OnboardingStep.GOALS]: "Ok, that's everything I need to start with. Now, how can I help you [Name]?",
    },
    [ProcessType.ANALYTICS_UPDATE]: {
      [AnalyticsUpdateStep.ENGAGEMENT_RATE_L7]: "What else can I help you with?",
    },
    [ProcessType.IDEATION]: {
      [IdeationStep.IDEAS]: "What else can I help you with?",
    },
    [ProcessType.PLANNING]: {
      [PlanningStep.WEEKLY_PLAN]: "What else can I help you with?",
    },
    [ProcessType.SHOW_CONTENT_PLAN]: {
      [ShowContentPlanStep.SHOW_CONTENT_PLAN]: "What else can I help you with?",
    },
    [ProcessType.BRAINSTORM_CONTENT_IDEAS]: {
      [BrainstormQuickIdeasStep.IDEAS]: "What else can I help you with?",
    },
    [ProcessType.SHOW_CONTENT_IDEAS]: {
      [ShowContentIdeasStep.SHOW_CONTENT_IDEAS]: "What else can I help you with?",
    },
  };

  return messageMap[previousProcess.type]?.[previousProcess.step] || "What else can I help you with?";
};
