/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { Idea } from '../Constants/Type';
import { Colours } from '../Constants/Colours';
import { Text } from '../Constants/Text';
import { ReactComponent as TrashIcon } from '../icons/Trash.svg';
import { ReactComponent as RefreshIcon } from '../icons/Refresh.svg';
import { ReactComponent as PenIcon } from '../icons/Pen.svg';
import { ReactComponent as CrossIcon } from '../icons/Cross.svg';
import { ReactComponent as CheckIcon } from '../icons/Check.svg';
import { ReactComponent as GenerateTextIcon } from '../icons/GenerateText.svg';
import { ReactComponent as OpenIcon } from '../icons/Open.svg';
import Spinner from './Spinner';
import Tooltip from './Tooltip';

interface EditableIdeaProps {
  idea: Idea;
  isRefreshing: boolean;
  isEditing: boolean;
  isDisabled: boolean;
  editedTitle: string;
  editedDescription: string;
  onEditedTitleChange: (value: string) => void;
  onEditedDescriptionChange: (value: string) => void;
  onDelete: (id: string) => void;
  onRefresh: (id: string) => void;
  onEdit: (idea: Idea) => void;
  onCancel: () => void;
  onSave: (id: string) => void;
  onGenerateText: (id: string) => void;
  generatingTextIdeaId?: string;
  isLoading?: boolean;
  onOpenDetails: (idea: Idea) => void;
}

const ideaItemStyles = css`
  display: flex;
  flex-direction: column;
  padding: 25px;
  text-align: left;
  gap: 10px;
  position: relative;

  &:not(:last-child) {
    border-bottom: 1px solid ${Colours.DIVIDER};
  }
`;

const titleStyles = css`
  font-size: ${Text.HEADING.size};
  font-weight: ${Text.HEADING.weight};
  color: ${Colours.TEXT};
`;

const descriptionStyles = css`
  font-size: ${Text.SMALL_BODY.size};
  color: ${Colours.TEXT};
`;

const buttonStyles = css`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: ${Colours.TEXT};
  opacity: 0.6;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
`;

const buttonContainerStyles = css`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 12px;
  justify-content: flex-end;
`;

const disabledStyles = css`
  opacity: 0.4;
  cursor: not-allowed;
`;

const textAreaStyles = css`
  width: 100%;
  min-height: 100px;
  border-radius: 20px;
  font-size: ${Text.SMALL_BODY.size};
  font-family: inherit;
  resize: vertical;
  background-color: ${Colours.USER};
  border: none;
  padding: 20px;
  box-sizing: border-box;

  &:focus {
    outline: 1px solid ${Colours.DIVIDER};
  }
`;

const titleInputStyles = css`
  width: 100%;
  font-size: ${Text.HEADING.size};
  font-weight: ${Text.HEADING.weight};
  font-family: inherit;
  background-color: ${Colours.USER};
  border: none;
  padding: 10px;
  border-radius: 10px;
  box-sizing: border-box;

  &:focus {
    outline: 1px solid ${Colours.DIVIDER};
  }
`;

const dateStyles = css`
  font-size: ${Text.LABEL.size};
  color: ${Colours.TEXT};
`;

const formatDate = (dateStr: string | undefined): string => {
  if (!dateStr) return '';
  const date = new Date(dateStr);
  const today = new Date();
  const nextWeek = new Date();
  nextWeek.setDate(today.getDate() + 7);

  // Reset time components for accurate date comparison
  today.setHours(0, 0, 0, 0);
  date.setHours(0, 0, 0, 0);

  // Check if date is today
  if (date.getTime() === today.getTime()) {
    return 'Today';
  }

  if (date <= nextWeek && date >= today) {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayName = days[date.getDay()];
    const dayOfMonth = date.getDate();
    const suffix = ['th', 'st', 'nd', 'rd'][(dayOfMonth % 10 > 3 ? 0 : dayOfMonth % 10)];
    return `${dayName} ${dayOfMonth}${suffix}`;
  }

  return dateStr;
};

const EditableIdea: React.FC<EditableIdeaProps> = ({
  idea,
  isRefreshing,
  isEditing,
  isDisabled,
  editedTitle,
  editedDescription,
  onEditedTitleChange,
  onEditedDescriptionChange,
  onDelete,
  onRefresh,
  onEdit,
  onCancel,
  onSave,
  onGenerateText,
  generatingTextIdeaId,
  isLoading,
  onOpenDetails
}) => {
  return (
    <div 
      css={[
        ideaItemStyles,
        isRefreshing && css`opacity: 0.7;`
      ]}
    >
      <div css={dateStyles}>
        {formatDate(idea.date)}
      </div>
      {isEditing ? (
        <>
          <input
            css={titleInputStyles}
            value={editedTitle}
            onChange={(e) => onEditedTitleChange(e.target.value)}
            disabled={isLoading}
          />
          <textarea
            css={textAreaStyles}
            value={editedDescription}
            onChange={(e) => onEditedDescriptionChange(e.target.value)}
            disabled={isLoading}
          />
          <div css={buttonContainerStyles}>
            <Tooltip content="Expand Idea">
              <button 
                css={[buttonStyles, isLoading && disabledStyles]}
                onClick={() => onGenerateText(idea.id)}
                aria-label="Generate text"
                disabled={generatingTextIdeaId === idea.id}
              >
                {generatingTextIdeaId === idea.id ? (
                  <Spinner />
                ) : (
                  <GenerateTextIcon />
                )}
              </button>
            </Tooltip>
            <button 
              css={[buttonStyles, isLoading && disabledStyles]}
              onClick={onCancel}
              aria-label="Cancel edit"
              disabled={isLoading}
            >
              <CrossIcon />
            </button>
            <button 
              css={[buttonStyles, isLoading && disabledStyles]}
              onClick={() => onSave(idea.id)}
              aria-label="Save edit"
              disabled={isLoading}
            >
              <CheckIcon />
            </button>
          </div>
        </>
      ) : (
        <>
          <div css={titleStyles}>{idea.title}</div>
          <div css={descriptionStyles}>{idea.description}</div>
          <div css={buttonContainerStyles}>
            <Tooltip content="Open details">
              <button 
                css={[buttonStyles, isDisabled && disabledStyles]}
                onClick={() => onOpenDetails(idea)}
                aria-label="Open idea details"
                disabled={isDisabled}
              >
                <OpenIcon />
              </button>
            </Tooltip>
            <Tooltip content="Delete idea">
              <button 
                css={[buttonStyles, isDisabled && disabledStyles]}
                onClick={() => onDelete(idea.id)}
                aria-label="Delete idea"
                disabled={isDisabled}
              >
                <TrashIcon />
              </button>
            </Tooltip>
            <Tooltip content="Regenerate idea">
              <button 
                css={[buttonStyles, isDisabled && disabledStyles]}
                onClick={() => onRefresh(idea.id)}
                aria-label="Refresh idea"
                disabled={isDisabled}
              >
                {isRefreshing ? <Spinner /> : <RefreshIcon />}
              </button>
            </Tooltip>
            <Tooltip content="Edit idea">
              <button 
                css={[buttonStyles, isDisabled && disabledStyles]}
                onClick={() => onEdit(idea)}
                aria-label="Edit idea"
                disabled={isDisabled}
              >
                <PenIcon />
              </button>
            </Tooltip>
          </div>
        </>
      )}
    </div>
  );
};

export default EditableIdea;
