/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Button from './Button';
import { Colours } from '../Constants/Colours';
import { Text } from '../Constants/Text';

interface ConfirmationModalProps {
  isOpen: boolean;
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
  isConfirming?: boolean;
  confirmText?: string;
  cancelText?: string;
}

const modalOverlayStyles = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
`;

const modalContentStyles = css`
  background: ${Colours.BACKGROUND};
  padding: 25px;
  border-radius: 30px;
  max-width: 400px;
  text-align: center;
`;

const titleStyles = css`
  text-align: left;
  font-size: ${Text.SUBTITLE.size};
  font-weight: ${Text.SUBTITLE.weight};
  color: ${Colours.TEXT};
  margin-bottom: 12px;
`;

const messageStyles = css`
  font-weight: ${Text.BODY.weight};
  font-size: ${Text.BODY.size};
  color: ${Colours.TEXT};
  margin-bottom: 24px;
`;

const buttonContainerStyles = css`
  display: flex;
  gap: 12px;
  justify-content: space-between;
  margin-top: 24px;
  
`;

const ConfirmationModal = ({
  isOpen,
  title,
  message,
  onConfirm,
  onCancel,
  isConfirming = false,
  confirmText = 'Delete',
  cancelText = 'Cancel'
}: ConfirmationModalProps) => {
  if (!isOpen) return null;

  return (
    <div css={modalOverlayStyles}>
      <div css={modalContentStyles}>
        <div css={titleStyles}>{title}</div>
        <div css={messageStyles}>{message}</div>
        <div css={buttonContainerStyles}>
          <Button variant="secondary" onClick={onConfirm} disabled={isConfirming}>
            {isConfirming ? 'Deleting...' : confirmText}
          </Button>
          <Button variant="tertiary" onClick={onCancel} disabled={isConfirming}>
            {cancelText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
