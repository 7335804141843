/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { css, keyframes } from '@emotion/react';
import { Idea } from '../Constants/Type';
import { Colours } from '../Constants/Colours';
import { Text } from '../Constants/Text';
import { ReactComponent as RightArrowIcon } from '../icons/Right-arrow.svg';
import { ReactComponent as TrashIcon } from '../icons/Trash.svg';
import { ReactComponent as RefreshIcon } from '../icons/Refresh.svg';
import { ReactComponent as PenIcon } from '../icons/Pen.svg';
import { ReactComponent as CrossIcon } from '../icons/Cross.svg';
import { ReactComponent as CheckIcon } from '../icons/Check.svg';
import { ReactComponent as GenerateTextIcon } from '../icons/GenerateText.svg';
import Spinner from './Spinner';
import Tooltip from './Tooltip';
import Button from './Button';

interface IdeaPanelProps {
  idea: Idea | null;
  isOpen: boolean;
  onClose: () => void;
  onDelete: (id: string) => void;
  onRefresh: (id: string) => void;
  onUpdate: (
    id: string, 
    title: string, 
    description: string,
    postDescription: string,
    postHashtags: string[]
  ) => void;
  onGenerateText: (id: string, title: string, description: string, callback: (newTitle: string, newDescription: string) => void) => void;
  onGeneratePostContent: (params: { ideaId: string, title: string, description: string }) => void;
  isLoading?: boolean;
  refreshingIdeaId?: string;
  generatingTextIdeaId?: string;
  isGeneratingPostContent: boolean;
}

const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(100% + 20px));
  }
`;

const panelStyles = (isOpen: boolean) => css`
  position: fixed;
  top: 0;
  right: 0;
  width: 600px;
  height: 100vh;
  background-color: ${Colours.BACKGROUND};
  border-left: 1px solid ${Colours.DIVIDER};
  z-index: 1000;
  animation: ${isOpen ? slideIn : slideOut} 0.2s ease-in-out forwards;
`;

const headerStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

const closeButtonStyles = css`
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  color: ${Colours.TEXT};
  opacity: 0.6;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
`;

const contentContainerStyles = css`
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: calc(100% - 60px); // Adjust for header
  overflow-y: auto;
`;

const buttonContainerStyles = css`
  display: flex;
  gap: 12px;
  justify-content: flex-end;
`;

const buttonStyles = css`
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  color: ${Colours.TEXT};
  opacity: 0.6;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
`;

const inputStyles = css`
  width: 100%;
  font-size: ${Text.TITLE.size};
  font-weight: ${Text.TITLE.weight};
  font-family: inherit;
  background-color: ${Colours.USER};
  border: none;
  padding: 15px;
  border-radius: 10px;
  box-sizing: border-box;

  &:focus {
    outline: 1px solid ${Colours.DIVIDER};
  }
`;

const textAreaStyles = css`
  width: 100%;
  min-height: 200px;
  border-radius: 10px;
  font-size: ${Text.BODY.size};
  font-family: inherit;
  resize: vertical;
  background-color: ${Colours.USER};
  border: none;
  padding: 15px;
  box-sizing: border-box;

  &:focus {
    outline: 1px solid ${Colours.DIVIDER};
  }
`;

const dateStyles = css`
  font-size: ${Text.LABEL.size};
  color: ${Colours.TEXT};
`;

const titleStyles = css`
  font-size: ${Text.TITLE_SMALL.size};
  font-weight: ${Text.TITLE.weight};
  color: ${Colours.TEXT};
`;

const descriptionStyles = css`
  font-size: ${Text.BODY.size};
  color: ${Colours.TEXT};
  white-space: pre-wrap;
`;

const infoContainerStyles = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: left;
`;

const postSectionStyles = css`
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid ${Colours.DIVIDER};
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const labelStyles = css`
  font-size: ${Text.LABEL.size};
  color: ${Colours.TEXT};
  margin-bottom: 8px;
  text-align: left;
`;

const fieldContainerStyles = css`
  display: flex;
  flex-direction: column;
`;

const generateButtonStyles = css`
  background: none;
  border: 1px solid ${Colours.DIVIDER};
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
  color: ${Colours.TEXT};
  font-size: ${Text.BODY.size};
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
  }
`;

const hashtagsInputStyles = css`
  ${inputStyles}
  font-size: ${Text.BODY.size};
  font-weight: normal;
`;

const formatDate = (dateStr: string | undefined): string => {
  if (!dateStr) return '';
  return new Date(dateStr).toLocaleDateString();
};

const IdeaPanel: React.FC<IdeaPanelProps> = ({
  idea,
  isOpen,
  onClose,
  onDelete,
  onRefresh,
  onUpdate,
  onGenerateText,
  onGeneratePostContent,
  isLoading,
  refreshingIdeaId,
  generatingTextIdeaId,
  isGeneratingPostContent,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState('');
  const [editedDescription, setEditedDescription] = useState('');
  const [editedPostDescription, setEditedPostDescription] = useState('');
  const [editedPostHashtags, setEditedPostHashtags] = useState<string[]>([]);

  if (!idea) return null;

  const handleEdit = () => {
    setIsEditing(true);
    setEditedTitle(idea.title || '');
    setEditedDescription(idea.description);
    setEditedPostDescription(idea.postDescription || '');
    setEditedPostHashtags(idea.postHashtags || []);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedTitle('');
    setEditedDescription('');
    setEditedPostDescription('');
    setEditedPostHashtags([]);
  };

  const handleSave = () => {
    onUpdate(idea.id, editedTitle, editedDescription, editedPostDescription, editedPostHashtags);
    setIsEditing(false);
  };

  const handleGenerateText = () => {
    onGenerateText(
      idea.id,
      editedTitle,
      editedDescription,
      (newTitle: string, newDescription: string) => {
        setEditedTitle(newTitle);
        setEditedDescription(newDescription);
      }
    );
  };

  const handleGeneratePostContent = () => {
    onGeneratePostContent({
      ideaId: idea.id,
      title: idea.title || '',
      description: idea.description
    });
  };

  const isDisabled = isLoading || 
    generatingTextIdeaId === idea?.id || 
    refreshingIdeaId === idea?.id ||
    isGeneratingPostContent;

  return (
    <div css={panelStyles(isOpen)}>
      <div css={headerStyles}>
        <button css={closeButtonStyles} onClick={onClose} aria-label="Close panel">
          <RightArrowIcon />
        </button>
      </div>
      <div css={contentContainerStyles}>
        <div css={buttonContainerStyles}>
          {isEditing ? (
            <>
              <Tooltip content="Expand idea">
                <button
                  css={[buttonStyles, isDisabled && disabledStyles]}
                  onClick={handleGenerateText}
                  disabled={isDisabled}
                >
                  {generatingTextIdeaId === idea?.id ? (
                    <Spinner />
                  ) : (
                    <GenerateTextIcon />
                  )}
                </button>
              </Tooltip>
              <button 
                css={[buttonStyles, isDisabled && disabledStyles]}
                onClick={handleCancel}
                disabled={isDisabled}
              >
                <CrossIcon />
              </button>
              <button 
                css={[buttonStyles, isDisabled && disabledStyles]}
                onClick={handleSave}
                disabled={isDisabled}
              >
                <CheckIcon />
              </button>
            </>
          ) : (
            <>
              <Tooltip content="Delete idea">
                <button
                  css={[buttonStyles, isDisabled && disabledStyles]}
                  onClick={() => onDelete(idea.id)}
                  disabled={isDisabled}
                >
                  <TrashIcon />
                </button>
              </Tooltip>
              <Tooltip content="Regenerate idea">
                <button
                  css={[buttonStyles, isDisabled && disabledStyles]}
                  onClick={() => onRefresh(idea.id)}
                  disabled={isDisabled}
                >
                  {refreshingIdeaId === idea.id ? <Spinner /> : <RefreshIcon />}
                </button>
              </Tooltip>
              <Tooltip content="Edit idea">
                <button
                  css={[buttonStyles, isDisabled && disabledStyles]}
                  onClick={handleEdit}
                  disabled={isDisabled}
                >
                  <PenIcon />
                </button>
              </Tooltip>
            </>
          )}
        </div>
        {isEditing ? (
          <>
            <input
              css={inputStyles}
              value={editedTitle}
              onChange={(e) => setEditedTitle(e.target.value)}
              placeholder="Title"
              disabled={isDisabled}
            />
            <textarea
              css={textAreaStyles}
              value={editedDescription}
              onChange={(e) => setEditedDescription(e.target.value)}
              placeholder="Description"
              disabled={isDisabled}
            />
            <div css={postSectionStyles}>
              {!editedPostDescription && !editedPostHashtags.length ? (
                <Button
                  variant="secondary"
                  onClick={handleGeneratePostContent}
                  disabled={isDisabled}
                  icon={isGeneratingPostContent ? <Spinner /> : <GenerateTextIcon />}
                >
                  Generate Post Content
                </Button>
              ) : (
                <>
                  <div css={fieldContainerStyles}>
                    <div css={labelStyles}>Post Description</div>
                    <textarea
                      css={textAreaStyles}
                      value={editedPostDescription}
                      onChange={(e) => setEditedPostDescription(e.target.value)}
                      placeholder="Post Description"
                      disabled={isDisabled}
                    />
                  </div>
                  <div css={fieldContainerStyles}>
                    <div css={labelStyles}>Hashtags</div>
                    <input
                      css={hashtagsInputStyles}
                      value={editedPostHashtags.join(', ')}
                      onChange={(e) => setEditedPostHashtags(e.target.value.split(',').map(tag => tag.trim()))}
                      placeholder="Hashtags (comma-separated)"
                      disabled={isDisabled}
                    />
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <div css={infoContainerStyles}>
            <div css={dateStyles}>{formatDate(idea.date)}</div>
            <div css={titleStyles}>{idea.title}</div>
            <div css={descriptionStyles}>{idea.description}</div>
            <div css={postSectionStyles}>
              {!idea.postDescription && !idea.postHashtags?.length ? (
                <Button
                  variant="secondary"
                  onClick={handleGeneratePostContent}
                  disabled={isDisabled}
                  icon={isGeneratingPostContent ? <Spinner /> : <GenerateTextIcon />}
                >
                  Generate Post Content
                </Button>
              ) : (
                <>
                  {idea.postDescription && (
                    <div css={fieldContainerStyles}>
                      <div css={labelStyles}>Post Description</div>
                      <div css={descriptionStyles}>{idea.postDescription}</div>
                    </div>
                  )}
                  {idea.postHashtags && idea.postHashtags.length > 0 && (
                    <div css={fieldContainerStyles}>
                      <div css={labelStyles}>Hashtags</div>
                      <div css={descriptionStyles}>
                        {idea.postHashtags.map(tag => `#${tag}`).join(' ')}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const disabledStyles = css`
  opacity: 0.4;
  cursor: not-allowed;
`;

export default IdeaPanel;
