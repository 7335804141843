/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { Colours } from "../Constants/Colours";
import { Text } from "../Constants/Text";

type ButtonVariant = "primary" | "secondary" | "tertiary" | "widget";
type ButtonShape = "regular" | "circle";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  shape?: ButtonShape;
  children: React.ReactNode;
  icon?: React.ReactNode;
}

const baseButtonStyles = css`
  font-family: inherit;
  font-size: ${Text.BODY.size};
  font-weight: ${Text.HEADING.weight};
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  &:disabled {
    cursor: default;
  }
`;

const getVariantStyles = (variant: ButtonVariant) => {
  const variants = {
    primary: css`
      background-color: ${Colours.PRIMARY};
      color: ${Colours.TEXT};
      &:hover {
        background-color: ${Colours.PRIMARY};
      }
    `,
    secondary: css`
      background-color: ${Colours.SECONDARY};
      color: ${Colours.BACKGROUND};
      &:hover {
        opacity: 0.9;
      }

      svg {
        path {
          fill: ${Colours.BACKGROUND};
        }
      }
    `,
    tertiary: css`
      background-color: ${Colours.TERTIARY};
      color: ${Colours.TEXT};
      &:hover {
        background-color: ${Colours.TERTIARY};
      }
    `,
    widget: css`
      background-color: ${Colours.BACKGROUND};
      color: ${Colours.TEXT};
      border: 1px solid ${Colours.DIVIDER};
      &:hover {
        background-color: ${Colours.USER};
      }
    `,
  };
  return variants[variant];
};

const getShapeStyles = (shape: ButtonShape) => {
  const shapes = {
    regular: css`
      padding: 10px 20px;
      border-radius: 20px;
      min-width: 120px;
    `,
    circle: css`
      width: 40px;
      height: 40px;
      border-radius: 50%;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    `,
  };
  return shapes[shape];
};

const Button: React.FC<ButtonProps> = ({
  variant = "primary",
  shape = "regular",
  children,
  icon,
  ...props
}) => {
  return (
    <button
      css={[baseButtonStyles, getVariantStyles(variant), getShapeStyles(shape)]}
      {...props}
    >
      {icon && (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            transition: 'transform 0.3s ease'
          }}
        >
          {icon}
        </span>
      )}
      {children}
    </button>
  );
};

export default Button;
