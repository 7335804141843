/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer, Event, ToolbarProps } from 'react-big-calendar';
import moment from 'moment';
import { css } from '@emotion/react';
import { Colours } from './Constants/Colours';
import { Text } from './Constants/Text';
import { ReactComponent as LeftArrow } from './icons/Left-arrow.svg';
import { ReactComponent as RightArrow } from './icons/Right-arrow.svg';
import Button from './Components/Button';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useIdeasQuery } from './hooks/useIdeasQuery';
import { useAuth } from './context/AuthContext';
import { Idea } from './Constants/Type';
import IdeaPanel from './Components/IdeaPanel';
import ConfirmationModal from './Components/ConfirmationModal';
import { toast } from 'react-toastify';
import { useCompletion } from './hooks/useCompletion';

const localizer = momentLocalizer(moment);

// Add this type definition near the top of the file, after the imports
interface CustomEvent extends Event {
  id?: string;
}

const containerStyles = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
`;

const toolbarStyles = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const toolbarLabelStyles = css`
  font-size: ${Text.SUBTITLE.size};
  font-weight: ${Text.SUBTITLE.weight};
  color: ${Colours.TEXT};
`;

const toolbarButtonsStyles = css`
  display: flex;
  gap: 8px;
`;

const calendarContainerStyles = css`
  height: calc(100vh - 200px);
  margin: 0 20px;
  padding: 0 20px;
  background: ${Colours.BACKGROUND};
  border-radius: 30px;

  /* Override default calendar styles */
  .rbc-calendar {
    font-family: inherit;
  }

  .rbc-header {
    padding: 12px;
    font-weight: ${Text.SUBTITLE.weight};
    font-size: ${Text.BODY.size};
    border-bottom: 1px solid ${Colours.DIVIDER};
  }

  .rbc-month-view {
    border: 1px solid ${Colours.DIVIDER};
    background: ${Colours.BACKGROUND};
    border-radius: 30px;
    overflow: hidden;
  }

  .rbc-date-cell {
    padding: 8px;
    font-size: ${Text.BODY.size};
    text-align: center;

    .rbc-button-link {
      font-family: "Instrument Sans";
      font-size: ${Text.LABEL.size};
    }
  }

  .rbc-off-range-bg {
    background: none;
  }

  .rbc-off-range {
    color: ${Colours.DIVIDER};
  }

  .rbc-day-bg {
    border: none;
  }

  .rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid ${Colours.DIVIDER};
  }

  .rbc-month-row + .rbc-month-row {
    border-top: 1px solid ${Colours.DIVIDER};
  }

  .rbc-header + .rbc-header {
    border-left: 1px solid ${Colours.DIVIDER};
  }

  .rbc-month-row {
    border: none;
  }

  .rbc-today {
    background-color: ${Colours.HIGHLIGHT};
  }

  .rbc-row-bg {
    height: 100%;
  }

  .rbc-row-content {
    height: 100%;

    .rbc-row {
      &:last-child {
        height: calc(100% - 24px);
      }
    }
  }

  .rbc-row-segment {
    height: 100%;
    padding: 0 10px;
  }

  .rbc-event {
    height: calc(100% - 20px);
    margin: 0 0;
    background-color: ${Colours.PRIMARY};
    border: none;
    border-radius: 15px;
    color: ${Colours.TEXT};
    font-size: ${Text.SMALL_BODY.size};
    font-weight: ${Text.BODY.weight};
    padding: 8px 8px;
    display: flex;
    align-items: center;
  }

  .rbc-event-content {
    display: flex;
    height: 100%;
    width: 100%;
    min-width: 0;
  }

  .rbc-ellipsis, .rbc-show-more, .rbc-row-segment .rbc-event-content, .rbc-event-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    height: 100%;
    display: block;
    width: 100%;
  }

  .rbc-event.rbc-selected {
    background-color: ${Colours.PRIMARY};
  }

  .rbc-event:focus {
    outline: none;
  }

  .rbc-toolbar {
    margin-bottom: 20px;
    gap: 8px;
  }

  .rbc-toolbar .rbc-btn-group {
    gap: 8px;
    display: flex;
    
    button {
      color: ${Colours.TEXT};
      font-family: 'Instrument Sans';
      font-size: ${Text.BODY.size};
      font-weight: ${Text.HEADING.weight};
      border: 1px solid ${Colours.DIVIDER};
      background: ${Colours.BACKGROUND};
      padding: 10px 24px;
      border-radius: 20px;
      min-width: 120px;
      transition: all 0.3s ease;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: ${Colours.USER};
      }

      &.rbc-active {
        background-color: ${Colours.PRIMARY};
        border-color: ${Colours.PRIMARY};
      }
    }

    /* Remove default button group styling */
    & > button:first-of-type:not(:last-of-type) {
      border-radius: 20px;
    }

    & > button:not(:first-of-type):not(:last-of-type) {
      border-radius: 20px;
    }

    & > button:last-of-type:not(:first-of-type) {
      border-radius: 20px;
    }
  }

  .rbc-month-header {
    margin-bottom: 0;
  }
`;

const CustomToolbar: React.FC<ToolbarProps> = (props) => {
  const { onNavigate, label } = props;

  return (
    <div css={toolbarStyles}>
      <span css={toolbarLabelStyles}>{label}</span>
      <div css={toolbarButtonsStyles}>
        <Button
          variant="tertiary"
          onClick={() => onNavigate('TODAY')}
        >
          Today
        </Button>
        <Button
          variant="tertiary"
          shape="circle"
          onClick={() => onNavigate('PREV')}
        >
          <LeftArrow />
        </Button>
        <Button
          variant="tertiary"
          shape="circle"
          onClick={() => onNavigate('NEXT')}
        >
          <RightArrow />
        </Button>
      </div>
    </div>
  );
};

const IdeaEvent: React.FC<{ event: CustomEvent }> = ({ event }) => {
  return (
    <div
      css={css`
        height: 100%;
        width: 100%;
        font-size: ${Text.SMALL_BODY.size};
        font-weight: ${Text.BODY.weight};
        cursor: pointer;
      `}
    >
      {event.title}
    </div>
  );
};

const Plan: React.FC = () => {
  const { generateCompletion } = useCompletion();
  const { 
    ideas, 
    isLoading, 
    deleteIdea, 
    isDeleting, 
    refreshIdea, 
    isRefreshing, 
    updateIdea, 
    isUpdating,
    generatePostContent,
    isGeneratingPostContent,
   } = useIdeasQuery();
  const { userState } = useAuth();
  const [events, setEvents] = useState<CustomEvent[]>([]);
  const [selectedIdeaId, setSelectedIdeaId] = useState<string | null>(null);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [ideaToDelete, setIdeaToDelete] = useState<string | null>(null);
  const [refreshingIdeaId, setRefreshingIdeaId] = useState<string | undefined>(undefined);
  const [generatingTextIdeaId, setGeneratingTextIdeaId] = useState<string | undefined>(undefined);

  // Derive selected idea from the ideas array
  const selectedIdea = selectedIdeaId ? 
    ideas?.find(idea => idea.id === selectedIdeaId) ?? null : 
    null;

  useEffect(() => {
    const days = userState.planning?.days;  
    if (!days || !ideas?.length) return;

    // Get today's date and day number
    const today = new Date();
    const todayDayNumber = today.getDay();

    // Convert day strings to numbers (0-6, where 0 is Sunday)
    // Move this outside the effect to prevent recreating on each render
    const dayNumbers = days.map(day => {
      const dayIndex = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'].indexOf(day);
      return dayIndex;
    }).sort((a, b) => a - b);

    // Create events from ideas with stable date calculations
    const calendarEvents = ideas.map((idea, index) => {
      const currentDayIndex = index % dayNumbers.length;
      const targetDay = dayNumbers[currentDayIndex];
      
      let daysUntilNext = targetDay >= todayDayNumber
        ? targetDay - todayDayNumber
        : 7 - (todayDayNumber - targetDay);

      // Add extra weeks for subsequent iterations
      daysUntilNext += Math.floor(index / dayNumbers.length) * 7;

      const eventDate = new Date(today);
      eventDate.setDate(today.getDate() + daysUntilNext);
      eventDate.setHours(0, 0, 0, 0);

      return {
        id: idea.id,
        title: idea.title,
        start: eventDate,
        end: eventDate,
      };
    });

    setEvents(calendarEvents);
  }, [userState.planning?.days, ideas]);

  const handleSelectEvent = (event: CustomEvent) => {
    // Update to just set the ID
    if (event.id) {
      setSelectedIdeaId(event.id);
      setIsPanelOpen(true);
    }
  };

  const handleClosePanel = () => {
    setIsPanelOpen(false);
    // Clear the selected ID after the animation completes
    setTimeout(() => {
      setSelectedIdeaId(null);
    }, 300);
  };

  const handleDeleteClick = (ideaId: string) => {
    setIdeaToDelete(ideaId);
  };

  const handleConfirmDelete = async () => {
    if (ideaToDelete) {
      await deleteIdea(ideaToDelete);
      setIdeaToDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setIdeaToDelete(null);
  };

  const handleRefreshClick = (ideaId: string) => {
    setRefreshingIdeaId(ideaId);
    refreshIdea(ideaId, {
      onSettled: () => {
        setRefreshingIdeaId(undefined);
      }
    });
  };

  const handleUpdate = (ideaId: string, title: string, description: string, postDescription?: string, postHashtags?: string[]) => {
    updateIdea({ ideaId, title, description, postDescription, postHashtags });
  };

  const handleGenerateText = async (ideaId: string, title: string, description: string, 
    onSuccess: (newTitle: string, newDescription: string) => void) => {
    try {
      setGeneratingTextIdeaId(ideaId);

      const prompt = `
        You are tasked with helping a small business owner come up with a social media content idea.
        We have collected the following information about the business owner:
        ${JSON.stringify(userState)}

        The user has started writing an idea with:
        ${title ? `Title: ${title}` : 'No title provided'}
        ${description ? `Description: ${description}` : 'No description provided'}

        Please flesh out/enhance this idea, keeping any provided content as inspiration. If either the title or description is empty, create new content that fits with any provided text.
        If no text is provided, create a new idea.
        Important: the description isn't the description of the content for the user to post, it's a description of the idea itself.
        Keep the description of the idea concise and don't include unnecessary information like content type, format, platform e.g. dont say "Create a short video of ..."
        The tone value is a number between 1 and 5, where 1 is playful and 5 is serious.

        Return the idea in a JSON array like this: {ideas: [{title: 'idea1', description: 'description1'}]}
      `;

      const completion = await generateCompletion(prompt);
      const { ideas: [newIdea] } = JSON.parse(completion);
      
      onSuccess(newIdea.title, newIdea.description);
    } catch (error) {
      toast.error('Failed to generate text');
    } finally {
      setGeneratingTextIdeaId(undefined);
    }
  };

  const isGeneratingText = generatingTextIdeaId !== undefined;

  // todo: close the panel (in state) when deleting an idea. Atm we delete in the parent beacuse of the confirmation modal so hard to reset the state here.

  return (
    <div css={containerStyles}>
      <div css={calendarContainerStyles}>
        <Calendar<CustomEvent>
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: '100%' }}
          views={['month']}
          defaultView='month'
          components={{
            toolbar: CustomToolbar,
            event: IdeaEvent
          }}
          onSelectEvent={handleSelectEvent}
        />
      </div>
      <IdeaPanel
        idea={selectedIdea}
        isOpen={isPanelOpen}
        onClose={handleClosePanel}
        onDelete={handleDeleteClick}
        onRefresh={handleRefreshClick}
        onUpdate={handleUpdate}
        onGenerateText={handleGenerateText}
        isLoading={isLoading || isDeleting || isRefreshing || isUpdating || isGeneratingText || isGeneratingPostContent}
        refreshingIdeaId={refreshingIdeaId}
        generatingTextIdeaId={generatingTextIdeaId}
        onGeneratePostContent={generatePostContent}
        isGeneratingPostContent={isGeneratingPostContent}
      />
      <ConfirmationModal
        isOpen={ideaToDelete !== null}
        title="Delete Idea"
        message="Are you sure you want to delete this idea?"
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
        isConfirming={isDeleting}
      />
    </div>
  );
};

export default Plan;
