import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { ref, get, update, push } from 'firebase/database';
import { database } from '../firebase';
import { useAuth } from '../context/AuthContext';
import { toast } from 'react-toastify';
import { Days, Idea } from '../Constants/Type';
import { useCompletion } from './useCompletion';

export const useIdeasQuery = () => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const { generateCompletion } = useCompletion();

  // Helper function to check if a date is in the past
  const isExpired = (date: string | undefined) => {
    if (!date) return true; // Consider ideas without dates as expired
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset time to start of day
    return new Date(date) < today;
  };

  // Query for fetching active ideas
  const {
    data: ideas = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: ['ideas', user?.uid],
    queryFn: async (): Promise<Idea[]> => {
      if (!user) throw new Error('User not authenticated');
      
      const ideasRef = ref(database, `users/${user.uid}/state/ideation/ideas`);
      const snapshot = await get(ideasRef);
      
      const currentIdeas = snapshot.exists() ? snapshot.val() : [];
      
      // Split ideas into active and expired
      const { active, expired } = currentIdeas.reduce(
        (acc: { active: Idea[]; expired: Idea[] }, idea: Idea) => {
          if (isExpired(idea.date)) {
            acc.expired.push(idea);
          } else {
            acc.active.push(idea);
          }
          return acc;
        },
        { active: [], expired: [] }
      );

      // If we found expired ideas, move them to archived
      if (expired.length > 0) {
        // Get current archived ideas
        const archivedRef = ref(database, `users/${user.uid}/state/ideation/archived`);
        const archivedSnapshot = await get(archivedRef);
        const archivedIdeas = archivedSnapshot.exists() ? archivedSnapshot.val() : [];
        
        const updatedArchived = [...archivedIdeas, ...expired];
        
        await update(ref(database, `users/${user.uid}/state/ideation`), {
          ideas: active,
          archived: updatedArchived
        });

        // Invalidate archived ideas query to trigger a refetch
        queryClient.invalidateQueries({ queryKey: ['archivedIdeas', user?.uid] });
        
        return active;
      }
      
      return currentIdeas;
    },
    enabled: !!user,
  });

  // Query for fetching archived ideas
  const {
    data: archivedIdeas = [],
    isLoading: isLoadingArchived,
    error: archivedError,
  } = useQuery({
    queryKey: ['archivedIdeas', user?.uid],
    queryFn: async (): Promise<Idea[]> => {
      if (!user) throw new Error('User not authenticated');
      
      const archivedIdeasRef = ref(database, `users/${user.uid}/state/ideation/archived`);
      const snapshot = await get(archivedIdeasRef);
      
      return snapshot.exists() ? snapshot.val() : [];
    },
    enabled: !!user,
  });

  // Simplified helper function for getting next available date
  const getNextAvailableDate = (existingIdeas: Idea[], preferredDays: Days[]): string => {
    // Convert preferredDays to numbers (0-6, where 0 is Sunday)
    const preferredDayNumbers = preferredDays.map(day => {
      const dayMap: Record<Days, number> = {
        [Days.SUNDAY]: 0,
        [Days.MONDAY]: 1,
        [Days.TUESDAY]: 2,
        [Days.WEDNESDAY]: 3,
        [Days.THURSDAY]: 4,
        [Days.FRIDAY]: 5,
        [Days.SATURDAY]: 6
      };
      return dayMap[day];
    });

    // Find the starting date - either today or the day after the last idea
    let startDate = new Date();
    const lastIdea = existingIdeas[existingIdeas.length - 1];
    if (lastIdea?.date) {
      const lastDate = new Date(lastIdea.date);
      if (lastDate > startDate) {
        startDate = new Date(lastDate.setDate(lastDate.getDate() + 1));
      }
    }

    // Find next available preferred day
    while (!preferredDayNumbers.includes(startDate.getDay())) {
      startDate.setDate(startDate.getDate() + 1);
    }

    return startDate.toISOString().split('T')[0];
  };

  // Simplified recalculateDates helper
  const recalculateDates = (ideas: Idea[], preferredDays: Days[], startFromDate: string | undefined): Idea[] => {
    let currentDate = startFromDate ? new Date(startFromDate) : new Date();
    const preferredDayNumbers = preferredDays.map(day => {
      const dayMap: Record<Days, number> = {
        [Days.SUNDAY]: 0,
        [Days.MONDAY]: 1,
        [Days.TUESDAY]: 2,
        [Days.WEDNESDAY]: 3,
        [Days.THURSDAY]: 4,
        [Days.FRIDAY]: 5,
        [Days.SATURDAY]: 6
      };
      return dayMap[day];
    });

    return ideas.map(idea => {
      // Find next preferred day
      while (!preferredDayNumbers.includes(currentDate.getDay())) {
        currentDate.setDate(currentDate.getDate() + 1);
      }

      const newDate = currentDate.toISOString().split('T')[0];
      currentDate.setDate(currentDate.getDate() + 1);

      return { ...idea, date: newDate };
    });
  };

  // Modify deleteIdea mutation with simplified logic
  const deleteIdea = useMutation({
    mutationFn: async (ideaId: string) => {
      if (!user) throw new Error('User not authenticated');

      const userStateRef = ref(database, `users/${user.uid}/state`);
      const userStateSnapshot = await get(userStateRef);
      if (!userStateSnapshot.exists()) throw new Error('User state not found');
      const userState = userStateSnapshot.val();

      const userRef = ref(database, `users/${user.uid}/state/ideation/ideas`);
      const snapshot = await get(userRef);
      
      if (snapshot.exists()) {
        const currentIdeas = snapshot.val();
        const deletedIdeaIndex = currentIdeas.findIndex((idea: Idea) => idea.id === ideaId);
        
        if (deletedIdeaIndex === -1) throw new Error('Idea not found');

        // Keep ideas before the deleted one unchanged
        const ideasBeforeDelete = currentIdeas.slice(0, deletedIdeaIndex);
        // Get ideas after the deleted one that need date recalculation
        const ideasToRecalculate = currentIdeas.slice(deletedIdeaIndex + 1);

        // If there are ideas to recalculate, start from the deleted idea's date
        let updatedIdeas = ideasBeforeDelete;
        if (ideasToRecalculate.length > 0) {
          const startDate = currentIdeas[deletedIdeaIndex].date;
          
          const recalculatedIdeas = recalculateDates(
            ideasToRecalculate, 
            userState.planning.days,
            startDate
          );
          updatedIdeas = [...ideasBeforeDelete, ...recalculatedIdeas];
        }
        
        await update(ref(database, `users/${user.uid}/state/ideation`), {
          ideas: updatedIdeas
        });
        
        return updatedIdeas;
      }
      throw new Error('Ideas not found');
    },
    // Optimistic update
    onMutate: async (ideaId) => {
      // Cancel any outgoing refetches to avoid overwriting our optimistic update
      await queryClient.cancelQueries({ queryKey: ['ideas', user?.uid] });

      // Snapshot the previous value
      const previousIdeas = queryClient.getQueryData(['ideas', user?.uid]);

      // Optimistically update to the new value
      queryClient.setQueryData(['ideas', user?.uid], (old: Idea[] = []) => {
        return old.filter(idea => idea.id !== ideaId);
      });

      // Return context with the previous value
      return { previousIdeas };
    },
    // If mutation fails, use the context we returned above to roll back
    onError: (err, ideaId, context) => {
      queryClient.setQueryData(['ideas', user?.uid], context?.previousIdeas);
      toast.error('Failed to delete idea');
    },
    // Always refetch after error or success
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['ideas', user?.uid] });
    },
  });

  // Mutation for refreshing a single idea
  const refreshIdea = useMutation({
    mutationFn: async (ideaId: string) => {
      if (!user) throw new Error('User not authenticated');

      // Get current user state
      const userStateRef = ref(database, `users/${user.uid}/state`);
      const userStateSnapshot = await get(userStateRef);
      if (!userStateSnapshot.exists()) throw new Error('User state not found');
      const userState = userStateSnapshot.val();

      // Generate a single new idea
      const prompt = `
      You are tasked to come up with 1 social media content idea for a small business owner.
      We have collected the following information about the business owner:
      ${JSON.stringify(userState)}
      
      Use the information to carefully craft the content idea, taking into account all of the data including platform, content type, target audience, goals etc.
      The tone value is a number between 1 and 5, where 1 is playful and 5 is serious - this is very important as it will determine the tone of the content.
      Keep the description short and concise, and don't include unnecessary information in the idea description e.g. content type, format, platform, etc.

      Return the idea in a JSON array like this: {ideas: [{title: 'idea1', description: 'description1'}]}
      `;

      const completion = await generateCompletion(prompt);
      const { ideas: [newIdea] } = JSON.parse(completion);

      // Update the specific idea in the database
      const ideasRef = ref(database, `users/${user.uid}/state/ideation/ideas`);
      const snapshot = await get(ideasRef);
      
      if (snapshot.exists()) {
        const currentIdeas = snapshot.val();
        const updatedIdeas = currentIdeas.map((idea: Idea) => 
          idea.id === ideaId 
            ? { 
                ...idea, 
                title: newIdea.title, 
                description: newIdea.description 
              }
            : idea
        );
        
        await update(ref(database, `users/${user.uid}/state/ideation`), {
          ideas: updatedIdeas
        });
        
        return updatedIdeas;
      }
      throw new Error('Ideas not found');
    },
    onMutate: async (ideaId) => {
      await queryClient.cancelQueries({ queryKey: ['ideas', user?.uid] });
      const previousIdeas = queryClient.getQueryData(['ideas', user?.uid]);
      return { previousIdeas };
    },
    onError: (err, ideaId, context) => {
      queryClient.setQueryData(['ideas', user?.uid], context?.previousIdeas);
      toast.error('Failed to refresh idea');
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['ideas', user?.uid] });
      console.log('settled in query')
    },
  });

  // Add new mutation for updating ideas
  const updateIdea = useMutation({
    mutationFn: async ({ 
      ideaId, 
      title, 
      description,
      postDescription,
      postHashtags
    }: { 
      ideaId: string; 
      title: string;
      description: string;
      postDescription?: string;
      postHashtags?: string[];
    }) => {
      if (!user) throw new Error('User not authenticated');

      const ideasRef = ref(database, `users/${user.uid}/state/ideation/ideas`);
      const snapshot = await get(ideasRef);
      
      if (snapshot.exists()) {
        const currentIdeas = snapshot.val();
        const updatedIdeas = currentIdeas.map((idea: Idea) => 
          idea.id === ideaId 
            ? { ...idea, title, description, postDescription, postHashtags } 
            : idea
        );
        
        await update(ref(database, `users/${user.uid}/state/ideation`), {
          ideas: updatedIdeas
        });
        
        return updatedIdeas;
      }
      throw new Error('Ideas not found');
    },
    onMutate: async ({ ideaId, title, description, postDescription, postHashtags }) => {
      await queryClient.cancelQueries({ queryKey: ['ideas', user?.uid] });
      const previousIdeas = queryClient.getQueryData(['ideas', user?.uid]);

      queryClient.setQueryData(['ideas', user?.uid], (old: Idea[] = []) => {
        return old.map(idea => 
          idea.id === ideaId 
            ? { ...idea, title, description, postDescription, postHashtags }
            : idea
        );
      });

      return { previousIdeas };
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(['ideas', user?.uid], context?.previousIdeas);
      toast.error('Failed to update idea');
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['ideas', user?.uid] });
    },
  });

  // Modify createIdea mutation
  const createIdea = useMutation({
    mutationFn: async () => {
      if (!user) throw new Error('User not authenticated');

      // Get current user state
      const userStateRef = ref(database, `users/${user.uid}/state`);
      const userStateSnapshot = await get(userStateRef);
      if (!userStateSnapshot.exists()) throw new Error('User state not found');
      const userState = userStateSnapshot.val();

      const prompt = `
      You are tasked to come up with 1 social media content idea for a small business owner.
      We have collected the following information about the business owner:
      ${JSON.stringify(userState)}
      
      Use the information to carefully craft the content idea, taking into account all of the data including platform, content type, target audience, goals etc.
      The tone value is a number between 1 and 5, where 1 is playful and 5 is serious - this is very important as it will determine the tone of the content.
      Keep the description short and concise, and don't include unnecessary information in the idea description e.g. content type, format, platform, etc.

      Return the idea in a JSON array like this: {ideas: [{title: 'idea1', description: 'description1'}]}
      `;

      const completion = await generateCompletion(prompt);
      const { ideas: [newIdea] } = JSON.parse(completion);

      const ideasRef = ref(database, `users/${user.uid}/state/ideation/ideas`);
      const snapshot = await get(ideasRef);
      
      const currentIdeas = snapshot.exists() ? snapshot.val() : [];
      const newId = push(ref(database)).key;
      
      if (!newId) {
        throw new Error('Failed to generate new idea ID');
      }

      // Get next available date
      const nextDate = getNextAvailableDate(
        currentIdeas,
        userState.planning.days
      );

      const newIdeaWithId = {
        ...newIdea,
        id: newId,
        date: nextDate, // Add the date
      };
      
      const updatedIdeas = [...currentIdeas, newIdeaWithId];
      
      await update(ref(database, `users/${user.uid}/state/ideation`), {
        ideas: updatedIdeas
      });
      
      return newIdeaWithId;
    },
    onError: (err) => {
      toast.error('Failed to create new idea');
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['ideas', user?.uid] });
    },
  });

  // Modify createEmptyIdea mutation
  const createEmptyIdea = useMutation({
    mutationFn: async () => {
      if (!user) throw new Error('User not authenticated');

      // Get current user state to access preferred days
      const userStateRef = ref(database, `users/${user.uid}/state`);
      const userStateSnapshot = await get(userStateRef);
      if (!userStateSnapshot.exists()) throw new Error('User state not found');
      const userState = userStateSnapshot.val();

      const ideasRef = ref(database, `users/${user.uid}/state/ideation/ideas`);
      const snapshot = await get(ideasRef);
      
      const currentIdeas = snapshot.exists() ? snapshot.val() : [];
      const newId = push(ref(database)).key;
      
      if (!newId) {
        throw new Error('Failed to generate new idea ID');
      }

      // Get next available date
      const nextDate = getNextAvailableDate(
        currentIdeas,
        userState.planning.days
      );

      const newIdeaWithId = {
        id: newId,
        title: '',
        description: '',
        date: nextDate, // Add the date
      };
      
      const updatedIdeas = [...currentIdeas, newIdeaWithId];
      
      await update(ref(database, `users/${user.uid}/state/ideation`), {
        ideas: updatedIdeas
      });
      
      return newIdeaWithId;
    },
    onError: (err) => {
      toast.error('Failed to create empty idea');
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['ideas', user?.uid] });
    },
  });

  // Add new mutation for updating the entire ideas array
  const updateIdeas = useMutation({
    mutationFn: async ({ newIdeas, originalIdeas }: { newIdeas: Idea[], originalIdeas: Idea[] }) => {
      if (!user) throw new Error('User not authenticated');

      // Get user state to access preferred days
      const userStateRef = ref(database, `users/${user.uid}/state`);
      const userStateSnapshot = await get(userStateRef);
      if (!userStateSnapshot.exists()) throw new Error('User state not found');
      const userState = userStateSnapshot.val();

      // Find the first position where the order differs
      let firstDifferentIndex = newIdeas.findIndex((idea, index) => 
        idea.id !== originalIdeas[index]?.id
      );

      if (firstDifferentIndex === -1) return newIdeas;

      // Get the date from the idea that was originally at this position
      const startDate = originalIdeas[firstDifferentIndex]?.date || 
        new Date().toISOString().split('T')[0];

      // Recalculate all dates from the first changed position onwards
      const ideasToRecalculate = newIdeas.slice(firstDifferentIndex);

      const recalculatedIdeas = recalculateDates(
        ideasToRecalculate,
        userState.planning.days,
        startDate
      );

      const updatedIdeas = [
        ...newIdeas.slice(0, firstDifferentIndex),
        ...recalculatedIdeas
      ];

      await update(ref(database, `users/${user.uid}/state/ideation`), {
        ideas: updatedIdeas
      });
      
      return updatedIdeas;
    },
    onMutate: async ({ newIdeas }) => {
      await queryClient.cancelQueries({ queryKey: ['ideas', user?.uid] });
      const previousIdeas = queryClient.getQueryData(['ideas', user?.uid]);
      queryClient.setQueryData(['ideas', user?.uid], newIdeas);
      return { previousIdeas };
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(['ideas', user?.uid], context?.previousIdeas);
      toast.error('Failed to reorder ideas');
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['ideas', user?.uid] });
    },
  });

  // Add new mutation for reordering archived ideas
  const reorderArchivedIdeas = useMutation({
    mutationFn: async (newArchivedIdeas: Idea[]) => {
      if (!user) throw new Error('User not authenticated');
      
      await update(ref(database, `users/${user.uid}/state/ideation`), {
        archived: newArchivedIdeas
      });
      
      return newArchivedIdeas;
    },
    onMutate: async (newArchivedIdeas) => {
      await queryClient.cancelQueries({ queryKey: ['archivedIdeas', user?.uid] });
      const previousArchivedIdeas = queryClient.getQueryData(['archivedIdeas', user?.uid]);
      queryClient.setQueryData(['archivedIdeas', user?.uid], newArchivedIdeas);
      return { previousArchivedIdeas };
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(['archivedIdeas', user?.uid], context?.previousArchivedIdeas);
      toast.error('Failed to reorder archived ideas');
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['archivedIdeas', user?.uid] });
    },
  });

  // Add new mutation for handling moves between active and archived lists
  const moveIdeaBetweenLists = useMutation({
    mutationFn: async ({ 
      newActiveIdeas, 
      newArchivedIdeas,
      movedIdea
    }: { 
      newActiveIdeas: Idea[], 
      newArchivedIdeas: Idea[],
      movedIdea: Idea | null
    }) => {
      if (!user) throw new Error('User not authenticated');

      const userStateRef = ref(database, `users/${user.uid}/state`);
      const userStateSnapshot = await get(userStateRef);
      if (!userStateSnapshot.exists()) throw new Error('User state not found');
      const userState = userStateSnapshot.val();

      let recalculatedActiveIdeas = newActiveIdeas;

      // Only recalculate dates if moving from archived to active
      if (movedIdea) {
        const insertIndex = newActiveIdeas.findIndex(idea => idea.id === movedIdea.id);
        
        // Keep ideas before insertion point unchanged
        const ideasBeforeInsert = newActiveIdeas.slice(0, insertIndex);
        
        // Get the start date for recalculation
        let startDate;
        if (insertIndex > 0) {
          // Use the day AFTER the previous idea's date
          // todo: if no date, start from today and don't add a day
          const previousDate = new Date(ideasBeforeInsert[insertIndex - 1].date || new Date().toISOString().split('T')[0]);
          previousDate.setDate(previousDate.getDate() + 1);
          startDate = previousDate.toISOString().split('T')[0];
        } else {
          // If inserting at the beginning, start from today
          startDate = new Date().toISOString().split('T')[0];
        }

        // Recalculate dates for inserted idea and all subsequent ideas
        const ideasToRecalculate = newActiveIdeas.slice(insertIndex);
        const recalculatedIdeas = recalculateDates(
          ideasToRecalculate,
          userState.planning.days,
          startDate
        );

        recalculatedActiveIdeas = [...ideasBeforeInsert, ...recalculatedIdeas];
      }

      // Update both lists atomically
      await update(ref(database, `users/${user.uid}/state/ideation`), {
        ideas: recalculatedActiveIdeas,
        archived: newArchivedIdeas
      });
      
      return { active: recalculatedActiveIdeas, archived: newArchivedIdeas };
    },
    onMutate: async ({ newActiveIdeas, newArchivedIdeas }) => {
      await queryClient.cancelQueries({ queryKey: ['ideas', user?.uid] });
      await queryClient.cancelQueries({ queryKey: ['archivedIdeas', user?.uid] });
      
      const previousActive = queryClient.getQueryData(['ideas', user?.uid]);
      const previousArchived = queryClient.getQueryData(['archivedIdeas', user?.uid]);
      
      queryClient.setQueryData(['ideas', user?.uid], newActiveIdeas);
      queryClient.setQueryData(['archivedIdeas', user?.uid], newArchivedIdeas);
      
      return { previousActive, previousArchived };
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(['ideas', user?.uid], context?.previousActive);
      queryClient.setQueryData(['archivedIdeas', user?.uid], context?.previousArchived);
      toast.error('Failed to move idea between lists');
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['ideas', user?.uid] });
      queryClient.invalidateQueries({ queryKey: ['archivedIdeas', user?.uid] });
    },
  });

  // Add new mutation for generating post content
  const generatePostContent = useMutation({
    mutationFn: async ({ ideaId, title, description }: { 
      ideaId: string; 
      title: string;
      description: string;
    }) => {
      if (!user) throw new Error('User not authenticated');

      // Get current user state
      const userStateRef = ref(database, `users/${user.uid}/state`);
      const userStateSnapshot = await get(userStateRef);
      if (!userStateSnapshot.exists()) throw new Error('User state not found');
      const userState = userStateSnapshot.val();

      // Generate post content using AI
      const prompt = `
        You are tasked with creating social media post content based on a content idea.
        We have collected the following information about the business owner:
        ${JSON.stringify(userState)}

        The content idea is:
        Title: ${title}
        Idea Description: ${description}

        Please generate:
        1. A compelling post description that the user can use to accompany the post. Do not include any hashtags.
        2. A list of relevant hashtags (maximum 5) for optimising the posts reach on social media

        Return the content in JSON format like this: 
        {
          postDescription: "string",
          postHashtags: ["string"]
        }
      `;

      const completion = await generateCompletion(prompt);
      const { postDescription, postHashtags } = JSON.parse(completion);

      // Update the idea in the database
      const ideasRef = ref(database, `users/${user.uid}/state/ideation/ideas`);
      const snapshot = await get(ideasRef);
      
      if (snapshot.exists()) {
        const currentIdeas = snapshot.val();
        const updatedIdeas = currentIdeas.map((idea: Idea) => 
          idea.id === ideaId 
            ? { ...idea, postDescription, postHashtags } 
            : idea
        );
        
        await update(ref(database, `users/${user.uid}/state/ideation`), {
          ideas: updatedIdeas
        });
        
        return updatedIdeas;
      }
      throw new Error('Ideas not found');
    },
    onMutate: async ({ ideaId }) => {
      await queryClient.cancelQueries({ queryKey: ['ideas', user?.uid] });
      const previousIdeas = queryClient.getQueryData(['ideas', user?.uid]);
      return { previousIdeas };
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(['ideas', user?.uid], context?.previousIdeas);
      toast.error('Failed to generate post content');
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['ideas', user?.uid] });
    },
  });

  return {
    ideas,
    isLoading,
    error,
    archivedIdeas,
    isLoadingArchived,
    archivedError,
    deleteIdea: deleteIdea.mutate,
    isDeleting: deleteIdea.isPending,
    refreshIdea: refreshIdea.mutate,
    isRefreshing: refreshIdea.isPending,
    updateIdea: updateIdea.mutate,
    isUpdating: updateIdea.isPending,
    createIdea: createIdea.mutate,
    isCreating: createIdea.isPending,
    createEmptyIdea: createEmptyIdea.mutate,
    isCreatingEmpty: createEmptyIdea.isPending,
    updateIdeas: updateIdeas.mutate,
    isUpdatingOrder: updateIdeas.isPending,
    reorderArchivedIdeas: reorderArchivedIdeas.mutate,
    isReorderingArchived: reorderArchivedIdeas.isPending,
    moveIdeaBetweenLists: moveIdeaBetweenLists.mutate,
    isMovingBetweenLists: moveIdeaBetweenLists.isPending,
    generatePostContent: generatePostContent.mutate,
    isGeneratingPostContent: generatePostContent.isPending,
  };
};
