/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Idea } from "../Constants/Type";
import { Colours } from "../Constants/Colours";
import { ReactComponent as DragIcon } from '../icons/Drag.svg';

interface SortableIdeaProps {
  idea: Idea;
  isRefreshing: boolean;
  isEditing: boolean;
  isDisabled: boolean;
  isLastItem?: boolean;
  children: React.ReactNode;
}

const dragHandleStyles = css`
  cursor: grab;
  padding: 25px;
  padding-right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    opacity: 0.8;
  }
  
  &:active {
    cursor: grabbing;
  }

svg {
    path {
      fill: ${Colours.DIVIDER};
    }
  }
`;

const containerStyles = css`
  display: flex;
  align-items: stretch;
`;

export const SortableIdea: React.FC<SortableIdeaProps> = ({
  idea,
  isRefreshing,
  isEditing,
  isDisabled,
  isLastItem = false,
  children
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: idea.id, disabled: isEditing || isDisabled });

  const style = {
    transform: CSS.Translate.toString(transform),
    opacity: isDragging ? 0.5 : undefined,
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      css={[
        containerStyles,
        !isLastItem && !isDragging && css`border-bottom: 1px solid ${Colours.DIVIDER};`,
        isDragging && css`
          border-bottom: none;
        `,
        isRefreshing && css`opacity: 0.7;`
      ]}
    >
      <div css={dragHandleStyles} {...attributes} {...listeners}>
        <DragIcon />
      </div>
      <div css={css`flex: 1;`}>
        {children}
      </div>
    </div>
  );
};
