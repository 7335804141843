/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { Idea } from '../Constants/Type';
import { Colours } from '../Constants/Colours';
import { Text } from '../Constants/Text';

interface ArchivedIdeaProps {
  idea: Idea;
}

const ideaItemStyles = css`
  display: flex;
  flex-direction: column;
  padding: 25px;
  text-align: left;
  gap: 10px;
  position: relative;
  opacity: 0.6;

  &:not(:last-child) {
    border-bottom: 1px solid ${Colours.DIVIDER};
  }
`;

const titleStyles = css`
  font-size: ${Text.HEADING.size};
  font-weight: ${Text.HEADING.weight};
  color: ${Colours.TEXT};
`;

const descriptionStyles = css`
  font-size: ${Text.SMALL_BODY.size};
  color: ${Colours.TEXT};
`;

const dateStyles = css`
  font-size: ${Text.LABEL.size};
  color: ${Colours.TEXT};
`;

const formatDate = (dateStr: string | undefined): string => {
  if (!dateStr) return '';
  return new Date(dateStr).toLocaleDateString();
};

const ArchivedIdea: React.FC<ArchivedIdeaProps> = ({ idea }) => {
  return (
    <div css={ideaItemStyles}>
      <div css={dateStyles}>
        {formatDate(idea.date)}
      </div>
      <div css={titleStyles}>{idea.title}</div>
      <div css={descriptionStyles}>{idea.description}</div>
    </div>
  );
};

export default ArchivedIdea;
